<template>
  <div class="page-container">
    <v-card class="dealer-statistics-card card d-flex" min-height="712.5px">
      <v-tabs class="statistic-tabs" v-model="tab">
        <v-tab class="dealer-orders-tab" key="dealer-orders" href="#dealer-orders">Подробная статистика по заказам
          товаров
        </v-tab>
        <v-tab key="visit-orders" href="#request-orders">Подробная статистика по заявкам</v-tab>
        <v-tab key="visit-orders" href="#visit-orders">Подробная статистика по поездкам</v-tab>
        <v-tab key="education-orders" href="#education-orders">Подробная статистика по обучению</v-tab>
        <v-tab key="bonus-flow" href="#bonus-flow">Расход бонусов</v-tab>
        <v-tab key="general-statistics" href="#general-statistics">Общая статистика</v-tab>
      </v-tabs>
      <v-tabs-items touchless class="statistic-tabs-items" v-model="tab">
        <v-tab-item class="dealer-orders-tab-item" key="dealer-orders" id="dealer-orders">
          <dealer-orders-tab/>
        </v-tab-item>
        <v-tab-item class="request-orders-tab-item" key="request-orders" id="request-orders">
          <request-orders-tab/>
        </v-tab-item>
        <v-tab-item class="visit-orders-tab-item" key="visit-orders" id="visit-orders">
          <visit-orders-tab/>
        </v-tab-item>
        <v-tab-item class="education-orders-tab-item" key="education-orders" id="education-orders">
          <education-orders-tab/>
        </v-tab-item>
        <v-tab-item class="points-flow-items-tab-item" key="bonus-flow" id="bonus-flow">
          <points-flow-tab/>
        </v-tab-item>
        <v-tab-item class="general-statistics-tab-item" key="general-statistics" id="general-statistics">
          <general-statistics-tab/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import DealerOrdersTab from "./tabs/DealerOrdersTab";
import VisitOrdersTab from "./tabs/VisitOrdersTab";
import RequestOrdersTab from "./tabs/RequestOrdersTab";
import EducationOrdersTab from "./tabs/EducationOrdersTab";
import PointsFlowTab from "./tabs/PointsFlowTab";
import GeneralStatisticsTab from "./tabs/GeneralStatisticsTab";

export default {
  name: "dealer",
  components: {
    DealerOrdersTab,
    VisitOrdersTab,
    EducationOrdersTab,
    PointsFlowTab,
    GeneralStatisticsTab,
    RequestOrdersTab
  },
  data: () => ({
    tab: null
  })
}
</script>

<style lang="sass" scoped>
.page-container
  height: 100%

.statistic-tabs
  min-width: 1024px
  flex: 0 1 auto
  width: auto
  margin-top: 20px
  margin-left: 20px

.statistic-tabs-items
  min-width: 1024px !important

.dealer-orders-tab-item, .visit-orders-tab-item, .points-flow-items-tab-item, .general-statistics-tab-item
  padding: 0 25px
</style>