<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="dealer-orders-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <!--        <template v-slot:actions="{props: {dataItem}}">-->
                    <!--          <td>-->
                    <!--            <div class="actionsColumn">-->
                    <!--              <CButton @click="cancelOrder(dataItem.id)" :disabled="!dataItem.can_cancel_order" class="actions-btn btn-square btn-info">-->
                    <!--                <i class="la la-remove"></i>-->
                    <!--                Отменить-->
                    <!--              </CButton>-->
                    <!--            </div>-->
                    <!--          </td>-->
                    <!--        </template>-->
                    <template v-slot:tpp_name_filter="{ methods, props }">
                        <dropdownlist
                            class="dealers-filter"
                            :data-items="ttp_filter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectAreaObject"
                        >
                        </dropdownlist>
                    </template>
                    <template v-slot:status_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="statusFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template v-slot:have_questions_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'have_questions',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template v-slot:have_questions="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success':
                                            dataItem.have_questions,
                                        'm-badge--danger':
                                            !dataItem.have_questions,
                                    }"
                                >
                                    {{
                                        dataItem.have_questions === 1
                                            ? 'ДА'
                                            : 'НЕТ'
                                    }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:have_lunch_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter('have_lunch', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:have_lunch="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success': dataItem.have_lunch,
                                        'm-badge--danger': !dataItem.have_lunch,
                                    }"
                                >
                                    {{
                                        dataItem.have_lunch === 1 ? 'ДА' : 'НЕТ'
                                    }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{ getStatusNameByKey(dataItem.status) }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapActions, mapGetters } from 'vuex'
    import BooleanFilter from '@/views/components/BooleanFilter'
    // import {showConfirm} from "@/lib/swal2";
    // import {showMessage} from "@/lib/toasted";
    import visitOrderStatuses from '@/config/visit_order_statuses'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'VisitOrdersTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            BooleanFilter,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData()
            await this.fetchVisitOrderFilter()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 0,
                gridPageable: { pageSizes: true },
                ttp_filter: [
                    { id: 1, name: 'ТПП г. Пермь' },
                    { id: 2, name: 'ТПП г. Алматы' },
                    { id: 3, name: 'Завод г. Торжок' },
                    { id: 4, name: 'ТПП г. Тюмень' },
                ],
                sort: [{ field: 'created_at', dir: 'desc' }],
                columns: [
                    {
                        field: 'number',
                        title: 'Номер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'created_at',
                        title: 'Дата создания заявки',
                        minWidth: 200,
                        width: '200px',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },

                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        filterCell: 'status_filter',
                        type: 'text',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'visit_at',
                        title: 'Дата поездки',
                        minWidth: 200,
                        width: '200px',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },
                    {
                        field: 'tpp_name',
                        title: 'Обьект',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        filterCell: 'tpp_name_filter',
                        type: 'text',
                    },
                    {
                        field: 'user_count',
                        title: 'Количество участников',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'have_questions',
                        title: 'Нужна ли сессия вопросы/ответы?',
                        cell: 'have_questions',
                        minWidth: 300,
                        width: '300px',
                        filter: 'text',
                        filterCell: 'have_questions_filter',
                        type: 'text',
                    },
                    {
                        field: 'have_lunch',
                        title: 'Нужен ли обед?',
                        cell: 'have_lunch',
                        minWidth: 300,
                        width: '300px',
                        filter: 'text',
                        filterCell: 'have_lunch_filter',
                        type: 'text',
                    },
                    // {
                    //   field: 'visit_tariff_basic_cost',
                    //   title: 'Базовая стоимость',
                    //   minWidth: 200,
                    //   width: '200px',
                    //   filter: 'numeric',
                    //   type: 'numeric'
                    // },
                    // {
                    //   field: 'full_cost',
                    //   title: 'Общая стоимость заказа',
                    //   minWidth: 200,
                    //   width: '200px',
                    //   filter: 'numeric',
                    //   type: 'numeric'
                    // },
                ],
                visitOrderStatuses,
            }
        },
        methods: {
            fetchData: api.orders.getVisitsNewOrders,
            ...mapActions('visit_orders', ['fetchVisitOrderFilter']),
            getStatusNameByKey(key) {
                const status = this.visitOrderStatuses.find(
                    item => item.key === key
                )
                return status ? status.name : null
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id)
                } else {
                    this.removeFilter('dealer_id')
                }

                this.updateTable(true)
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            selectAreaObject({ value }) {
                if (value.id) {
                    this.setFilter('tpp_id', 'eq', value.id)
                } else {
                    this.removeFilter('tpp_id')
                }

                this.updateTable(true)
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('status', 'eq', value.key)
                } else {
                    this.removeFilter('status')
                }

                this.updateTable(true)
            },
            async downloadReport() {
                try {
                    const response = await api.orders.getNewVisitOrdersReport(
                        toDataSourceRequestString({
                            filter: this.filter,
                            sort: this.sort,
                        })
                    )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute('download', `Заявки на поездку.xlsx`)
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('visit_orders', [
                'dealersListShort',
                'areaObjectListShort',
            ]),
            dealerFilter() {
                return [...this.dealersListShort]
            },
            areaObjectFilter() {
                return [...this.areaObjectListShort]
            },
            statusFilter() {
                return [...this.visitOrderStatuses]
            },
        },
    }
</script>

<style lang="sass" scoped>
    .dealer-orders-table
      margin-top: 20px
</style>
