<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="points-flow-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template
                        v-slot:points_flow_type_filter="{ methods, props }"
                    >
                        <dropdownlist
                            class="categories-filter"
                            :data-items="typeFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectType"
                        />
                    </template>
                    <template v-slot:date_filter="{ methods, props }">
                        <div class="d-flex align-end">
                            <datepicker
                                :default-show="defaultShow"
                                :start-date-input-settings="startDateInputSettings"
                                :value="value"
                                :end-date-input-settings="endDateInputSettings"
                                @change="dateChangeHandler"
                            />
                            <button
                                @click="clearDates"
                                title="Clear"
                                type="button"
                                class="k-button k-button-icon k-clear-button-visible"
                            >
                                <span class="k-icon k-i-filter-clear"></span>
                            </button>
                        </div>
                    </template>
                    <template v-slot:points_flow_type="{ props: { dataItem } }">
                        <td>
                            {{ getTypeNameByKey(dataItem.bonus_spending) }}
                        </td>
                    </template>
                    <template
                        v-slot:points_flow_category_filter="{ methods, props }"
                    >
                        <dropdownlist
                            class="categories-filter"
                            :data-items="categoryFilters"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectCategory"
                        />
                    </template>
                    <template v-slot:points_flow_category="{ props: { dataItem } }">
                        <td>
                          {{getCategoryNameByKey({dataItem})}}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { mapActions, mapGetters } from 'vuex'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import pointsFlowTypes from '@/config/points_flow_types'
    import categoryTypes from '@/config/points_flow_categories'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'
    import { DateRangePicker } from '@progress/kendo-vue-dateinputs';

    export default {
        name: 'PointsFlowTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            datepicker: DateRangePicker,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            await this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                sort: [{ field: 'date', dir: 'desc' }],
                value: {
                    start: null,
                    end: null,
                },
                startDateInputSettings: {
                    label: 'От',
                    format: 'dd.MM.yyyy',
                },
                endDateInputSettings: {
                    format: 'dd.MM.yyyy',
                    label: 'До',
                },
                columns: [
                    {
                        field: 'dealer_unique_id',
                        title: 'КССС',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'income_expense',
                        title: 'Списание/Начисление баллов',
                        minWidth: 300,
                        width: '300px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'points_flow_category',
                        title: 'Категория',
                        minWidth: 300,
                        cell: 'points_flow_category',
                        width: '300px',
                        filter: 'text',
                        type: 'text',
                        filterCell: 'points_flow_category_filter',
                    },
                    {
                        field: 'points_flow_type',
                        title: 'Тип',
                        cell: 'points_flow_type',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        filterCell: 'points_flow_type_filter',
                        type: 'text',
                    },
                    {
                        field: 'comment',
                        title: 'Примечание',
                        minWidth: 450,
                        width: '450px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'date',
                        title: 'Дата',
                        filter: 'date',
                        type: 'date',
                        minWidth: 400,
                        width: '400px',
                        filterCell: 'date_filter'
                    },
                ],
                pointsFlowTypes,
                categoryTypes
            }
        },
        methods: {
            fetchData: api.statistics.getPointsFlowItems,
            ...mapActions('points_flow_items', ['fetchDealerListShort']),
            getTypeNameByKey(key) {
                const type = this.pointsFlowTypes.find(item => item.key === key)
                return type ? type.name : null
            },
            getCategoryNameByKey({dataItem}) {
              switch (dataItem.bonus_spending) {
                case -1:
                  return 'Расход'
                case 0:
                  return 'Расход'
                case 1:
                  return 'Расход'
                case 2:
                  return 'Расход'
                case 3:
                  return 'Возврат'
                case 4:
                  return 'Возврат'
                case 5:
                  return 'Возврат'

                default:
                  return 'Начисление'
              }
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id)
                } else {
                    this.removeFilter('dealer_id')
                }

                this.updateTable(true)
            },
            dateChangeHandler({ value }) {
                this.value.start = value.start
                this.value.end = value.end
                const dateStart = new Date(value.start).toLocaleDateString('ru-RU');
                const dateEnd = new Date(value.end).toLocaleDateString('ru-RU');
                const dateToday = new Date(Date.now()).toLocaleDateString('ru-RU');

                if (dateStart === dateEnd) {
                    this.removeFilter('date');
                    this.setFilter('date', 'gtedatetime', dateStart);
                    this.updateTable(true);
                    return;
                } else {
                    const obj = [
                    {
                        field: 'date',
                        operator: 'gte',
                        value: dateStart + 'T00:00:00',
                    },
                    {
                        field: 'date',
                        operator: 'lte',
                        value: dateEnd === '01.01.1970' ? dateToday : dateEnd + 'T23:59:59',
                    },
                ];   
                this.setMultipleFilter('date', obj);
                this.updateTable(true);
                }                
            },
            clearDates() {
                this.value.start = null
                this.value.end = null
                this.clearDatesFilters()
                this.updateTable(true);
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            selectType({ value }) {
                if (typeof(value.key) === 'number') {
                    this.setFilter('bonus_spending', 'eq', value.key)
                } else {
                    this.removeFilter('points_flow_type')
                }

                this.updateTable(true)
            },
            selectCategory({ value }) {
                this.removeFilter('bonus_spending')
                if (value.key == 0) {
                    const obj = [
                        {
                            field: 'bonus_spending',
                            operator: 'gte',
                            value: -1,
                        },
                        {
                            field: 'bonus_spending',
                            operator: 'lte',
                            value: 2,
                        },
                    ];   
                    this.setMultipleFilter('bonus_spending', obj)
                } else if (value.key == 1){
                    const obj = [
                        {
                            field: 'bonus_spending',
                            operator: 'gte',
                            value: 3,
                        },
                        {
                            field: 'bonus_spending',
                            operator: 'lte',
                            value: 5,
                        },
                    ];   
                    this.setMultipleFilter('bonus_spending', obj)
                }   else if (value.key == 2){
                        this.setFilter('bonus_spending', 'eq', 6)
                } else {
                    this.removeFilter('bonus_spending')
                }

                this.updateTable(true)
            },
            async downloadReport() {
                try {
                    const response =
                        await api.statistics.getDealerPointsFlowItemsReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute(
                        'download',
                        `Расход бонусов(дилер).xlsx`
                    )
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('points_flow_items', ['dealersListShort']),
            dealerFilter() {
                return [...this.dealersListShort]
            },
            typeFilter() {
                return [...this.pointsFlowTypes]
            },
            categoryFilters() {
                return [...this.categoryTypes]
            }
        },
    }
</script>

<style lang="sass" scoped>
    .points-flow-table
      margin-top: 20px
</style>
