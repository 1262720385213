<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="dealer-orders-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <!--        <template v-slot:actions="{props: {dataItem}}">-->
                    <!--          <td>-->
                    <!--            <div class="actionsColumn">-->
                    <!--              <CButton @click="cancelOrder(dataItem.id)" :disabled="!dataItem.can_cancel_order" class="actions-btn btn-square btn-info">-->
                    <!--                <i class="la la-remove"></i>-->
                    <!--                Отменить-->
                    <!--              </CButton>-->
                    <!--            </div>-->
                    <!--          </td>-->
                    <!--        </template>-->
                    <template v-slot:dealer_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="dealerFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectDealer"
                        />
                    </template>
                    <template v-slot:area_object_filter="{ methods, props }">
                        <dropdownlist
                            class="dealers-filter"
                            :data-items="areaObjectFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectAreaObject"
                        >
                        </dropdownlist>
                    </template>
                    <template v-slot:status_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="statusFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template
                        v-slot:has_additional_services_filter="{
                            methods,
                            props,
                        }"
                    >
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'has_additional_services',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template
                        v-slot:has_additional_services="{ props: { dataItem } }"
                    >
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success':
                                            dataItem.has_additional_services,
                                        'm-badge--danger':
                                            !dataItem.has_additional_services,
                                    }"
                                >
                                    {{
                                        dataItem.has_additional_services
                                            ? 'ДА'
                                            : 'НЕТ'
                                    }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{ getStatusNameByKey(dataItem.status) }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import '@/views/grid_elements/translations';
    import api from '@/api';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { mapActions, mapGetters } from 'vuex';
    import BooleanFilter from '@/views/components/BooleanFilter';
    // import {showConfirm} from "@/lib/swal2";
    // import {showMessage} from "@/lib/toasted";
    import visitOrderStatuses from '@/config/visit_order_statuses';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';

    export default {
        name: 'VisitOrdersTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            BooleanFilter,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData();
            await this.fetchVisitOrderFilter();
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                sort: [{ field: 'datetime', dir: 'desc' }],
                columns: [
                    {
                        field: 'number',
                        title: 'Номер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        filterCell: 'dealer_filter',
                        type: 'text',
                    },
                    {
                        field: 'dealer_unique_id',
                        title: 'КССС',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'datetime',
                        title: 'Дата создания заявки',
                        minWidth: 200,
                        width: '200px',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },
                    {
                        field: 'visit_date',
                        title: 'Дата поездки',
                        minWidth: 200,
                        width: '200px',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },
                    {
                        field: 'area_object_name',
                        title: 'Обьект площадки',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        filterCell: 'area_object_filter',
                        type: 'text',
                    },
                    {
                        field: 'visit_tariff_count_of_people',
                        title: 'Количество человек',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'has_additional_services',
                        title: 'Наличие дополнительных услуг?',
                        cell: 'has_additional_services',
                        minWidth: 300,
                        width: '300px',
                        filter: 'text',
                        filterCell: 'has_additional_services_filter',
                        type: 'text',
                    },
                    {
                        field: 'visit_tariff_basic_cost',
                        title: 'Базовая стоимость',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'full_cost',
                        title: 'Общая стоимость заказа',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        filterCell: 'status_filter',
                        type: 'text',
                    },
                ],
                visitOrderStatuses,
            };
        },
        methods: {
            fetchData: api.orders.getVisitOrders,
            ...mapActions('visit_orders', ['fetchVisitOrderFilter']),
            getStatusNameByKey(key) {
                const status = this.visitOrderStatuses.find(
                    item => item.key === key
                );
                return status ? status.name : null;
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
            selectAreaObject({ value }) {
                if (value.id) {
                    this.setFilter('area_object_id', 'eq', value.id);
                } else {
                    this.removeFilter('area_object_id');
                }

                this.updateTable(true);
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('status', 'eq', value.key);
                } else {
                    this.removeFilter('status');
                }

                this.updateTable(true);
            },
            async downloadReport() {
                try {
                    const response = await api.orders.getVisitOrdersReport(
                        toDataSourceRequestString({
                            filter: this.filter,
                            sort: this.sort,
                        })
                    );

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `Заявки на поездку.xlsx`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } catch (e) {
                    console.warn(e);
                }
            },
        },
        computed: {
            ...mapGetters('visit_orders', [
                'dealersListShort',
                'areaObjectListShort',
            ]),
            dealerFilter() {
                return [...this.dealersListShort];
            },
            areaObjectFilter() {
                return [...this.areaObjectListShort];
            },
            statusFilter() {
                return [...this.visitOrderStatuses];
            },
        },
    };
</script>

<style lang="sass" scoped>
    .dealer-orders-table
      margin-top: 20px
</style>
