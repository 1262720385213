<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="dealer-orders-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    @click="cancelOrder(dataItem.id)"
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-remove"></i>
                                    Отменить
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template v-slot:area_object_filter="{ methods, props }">
                        <dropdownlist
                            class="dealers-filter"
                            :data-items="areaObjectFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectAreaObject"
                        >
                        </dropdownlist>
                    </template>
                    <template v-slot:status_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="statusFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template
                        v-slot:has_additional_services_filter="{
                            methods,
                            props,
                        }"
                    >
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'has_additional_services',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template
                        v-slot:has_additional_services="{ props: { dataItem } }"
                    >
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success':
                                            dataItem.has_additional_services,
                                        'm-badge--danger':
                                            !dataItem.has_additional_services,
                                    }"
                                >
                                    {{
                                        dataItem.has_additional_services
                                            ? 'ДА'
                                            : 'НЕТ'
                                    }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{ getStatusNameByKey(dataItem.status) }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapActions, mapGetters } from 'vuex'
    import BooleanFilter from '@/views/components/BooleanFilter'
    import visitOrderStatuses from '@/config/education_order_statuses'
    import cancelDealerEducationOrder from '@/api/entities/orders'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'
    import { showConfirm } from '@/lib/swal2'
    import { showMessage } from '@/lib/toasted'

    export default {
        name: 'EducationOrdersTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData()
            await this.fetchVisitOrderFilter()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 0,
                gridPageable: { pageSizes: true },
                sort: [{ field: 'created_at', dir: 'desc' }],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 160,
                        width: '160px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер заказа',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'created_at',
                        title: 'Дата создания заявки',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'agency_name',
                        title: 'Агентство',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'start_at',
                        title: 'Дата обучения',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'title',
                        title: 'Название курса',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'user_count',
                        title: 'Количество участников',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'format',
                        title: 'Онлайн/Офлайн',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'city',
                        title: 'Город проведения',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'price',
                        title: 'Сумма заказа',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        filterCell: 'status_filter',
                        minWidth: 160,
                        width: '160px',
                    },
                ],
                visitOrderStatuses,
            }
        },
        methods: {
            async cancelOrder(id) {
                const { isConfirmed } = await showConfirm(
                    'Вы уверены, что хотите отменить данный заказ?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.orders.cancelDealerEducationOrder(id)
                    showMessage(status, message)
                }

                await this.getData()
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            fetchData: api.orders.getDealerEducationOrders,
            ...mapActions('visit_orders', ['fetchVisitOrderFilter']),
            getStatusNameByKey(key) {
                const status = this.visitOrderStatuses.find(
                    item => item.key === key
                )
                return status ? status.name : null
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id)
                } else {
                    this.removeFilter('dealer_id')
                }

                this.updateTable(true)
            },
            selectAreaObject({ value }) {
                if (value.id) {
                    this.setFilter('area_object_id', 'eq', value.id)
                } else {
                    this.removeFilter('area_object_id')
                }

                this.updateTable(true)
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('status', 'eq', value.key)
                } else {
                    this.removeFilter('status')
                }

                this.updateTable(true)
            },
            async downloadReport() {
                try {
                    const response =
                        await api.orders.getEducationOrdersGeneralReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute(
                        'download',
                        `Подробная статистика по обучению.xlsx`
                    )
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('visit_orders', [
                'dealersListShort',
                'areaObjectListShort',
            ]),
            dealerFilter() {
                return [...this.dealersListShort]
            },
            areaObjectFilter() {
                return [...this.areaObjectListShort]
            },
            statusFilter() {
                return [...this.visitOrderStatuses]
            },
        },
    }
</script>

<style lang="sass" scoped>
    .dealer-orders-table
      margin-top: 20px
</style>
